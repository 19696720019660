<template>
  <div class="terms__body">
    <div
      class="terms__loading"
      v-if="loading"
    >
      <ui-loader :size="$pepper.Size.L" />
    </div>

    <div
      class="terms__content"
      v-html="terms"
      v-if="!loading"
    ></div>

    <div class="terms__accept">
      <div class="action-wrapper">
        <actions-button
          v-if="!loading"
          @click="onConfirm"
          :appearance="$pepper.Appearance.PRIMARY"
          :size="$pepper.Size.L"
          :disabled="counter > 0"
        >{{ $t('conn3ct-wallet.terms_confirm_button_action')}} {{ timer }}</actions-button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "SaylConnectLanding",

  data() {
    return {
      loading: true,
      terms: null,
      counter: 5
    }
  },

  computed: {
    ...mapState({
      bootstrap: state=> state.bootstrap.data
    }),

    isValid() {
      return this.optin === true &&
        !this.$basil.isNil(this.country) &&
        !this.$basil.isNil(this.$user.user.firstname) && !this.$basil.isEmpty(this.$user.user.firstname) &&
        !this.$basil.isNil(this.$user.user.lastname) && !this.$basil.isEmpty(this.$user.user.lastname)
    },

    title() {
      return this.$t('conn3ct-wallet.login_terms_title')
    },

    timer() {
      if(this.counter === 0) return

      return `(${this.counter}s)`
    }
  },

  methods: {
    async init() {
      try {
        this.loading = true
        this.terms = await this.$user.getTerms()
        setTimeout(this.handleTimeout, 1000)
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    handleTimeout() {
      this.counter--

      if(this.counter > 0) {
        setTimeout(this.handleTimeout, 1000)
      }
    },

    async onConfirm() {
      try {
        await this.$user.setTerms(this.optin)
        let user = await this.$user.getUser()

        if(user.lang) {
          this.setLocale(user.lang)
        }

        this.$emit('next')
      } catch(e) {
        $console.error(e)
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.init()
  }
}
</script>
